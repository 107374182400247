


import {
    Component,
    Vue,
    Mixins
} from "vue-property-decorator";
import Axios from "axios";
import {
    BASE_API_URL,
    setInstanceVariables
} from "../../../config";
import PreRequisiteHeader from "@/views/preRequisiteHeader/PreRequisiteHeader.vue";
import {
    parseTwoDigitYear
} from "moment";
import {
    HollowDotsSpinner
} from "epic-spinners";
import stores from "@/store/store";
import {
    BorrowerInformation,
    BorrowerDetails
} from "@/models/pos/borrower-info.model";
import {
    borrowerData
} from "@/models/borrowerData.model";
import {
    LoanAndProperty
} from "@/models/pos/loan-and-property.model";
import {
    DemographicInformation,
    DemographicDetails
} from "@/models/pos/demographic-info.model";
import {
    Acknowledgement,
    AckDetail
} from "@/models/pos/acknowledgement.model";
import {
    FinancialInfo
} from "@/models/pos/financial-info.model";
import {
    Declaration,
    DeclarationDetails
} from "@/models/pos/declaration.model";
import {
    TransactionDetail
} from "@/models/pos/transaction-detail.model";
const store: any = stores;
Component.registerHooks(["beforeRouteEnter"]);
import {
    isStepsNotComplete
} from "@/constants";
import _ from "lodash";
import { identifyUserSession } from '@/logrocket';

@Component({
    components: {
        PreRequisiteHeader,
        HollowDotsSpinner
    }
})
export default class ActivationPageComponent extends Vue {
    public borrowerData = new borrowerData();
    public borrowerInfo = new BorrowerInformation();
    public loanAndProperty = new LoanAndProperty();
    public demographicInfo = new DemographicInformation();
    public acknowledgement = new Acknowledgement();
    public financialInfo = new FinancialInfo();
    public declaration = new Declaration();
    public transactionDetail = new TransactionDetail();
    public user: any = {};
    public token: any = "";
    public displayfields = false;
    public submitForm = "validateAndSubmit";
    public inProcess = false;
    public verified = "";
    public emailExist = null;
    public blockSumbit = false;

    // after verification of email
    public async validateAndSubmit() {
        // after set password
        try {
            let formValid = await this.$validator.validateAll("login");
            if (formValid && !this.blockSumbit) {
                this.blockSumbit = true;
                let response = await Axios.post(
                    BASE_API_URL + "authentication/activateUserAccount",
                    this.user
                );
                this.blockSumbit = false;

                /**
                 * Configure LogRocket to capture basic user information.
                 */
                identifyUserSession(response.data);

                await this.$store.dispatch("setSession", response.data);
                await this.$store.dispatch("setSidenavLinks", response.data);
                await setInstanceVariables();
                if (response.data.type == "MLO") {
                    this.$router.replace({
                        name: "MLOPrerequisites"
                    });
                } else if (response.data.type == "Admin Staff") {
                    this.$router.replace({
                        name: "AdminStaffDashBoard"
                    });
                } else if (response.data.type == "Wemlo Processor") {
                    this.$router.replace({
                        name: "ProcessorDashBoard"
                    });

                    let response1111 = await Axios.post(
                        BASE_API_URL + "authentication/sendNewLoanToProcessor", {
                            userId: this.user.userId
                        }
                    );
                } else if (response.data.type == "Super Admin") {
                  this.$router.replace({
                    name: "SuperAdminDashBoard"
                  });
                } else if (
                    response.data.userInfo.fromUserType == "Super Admin" ||
                    ("Processor" && response.data.userInfo.inviteeToken != null)
                ) {
                    this.$router.replace({
                        name: "Prerequisites"
                    });
                } else {
                    this.$router.replace(response.data.borrowerRoute);
                }
            }
        } catch (error) {
            this.blockSumbit = false;
        }
    }

    public async verifyActivationToken() {
        try {
            let response = null;
            response = await Axios.post(
                BASE_API_URL + "authentication/verifyActivationToken", {
                    token: this.token
                }
            );
            if (!response.data.hasOwnProperty("status")) {
                this.user = response.data;
                this.$store.dispatch("setSession", response.data);
                this.$router.replace({
                    name: "ActivationPage"
                });
            } else {
                this.$snotify.error(response.data.message);
                this.$router.push({
                    name: "Login"
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    public openTermsAndConditionPage() {
        window.open(location.origin + "/terms-and-conditions", "_blank");
    }

    /**
     * We are saving the borrower data coming from the otherSite source
     */
    public async submitBorrowerData() {
        try {
            /**
             * Prevent multiple submits
             */
            this.inProcess = true;

            let formValid = await this.$validator.validateAll();
            if (!formValid) {
                return;
            }
            this.borrowerData.source = "otherSite";
            let obj = {};
            if (this.$route.query.brokerToken) {
                obj = {
                    token: this.$route.query.brokerToken,
                    edit: false,
                    password: this.user.password,
                    user: "broker"
                };
            } else {
                obj = {
                    token: this.$route.query.mloToken,
                    edit: false,
                    password: this.user.password,
                    user: "mlo"
                };
            }

            _.set(this.borrowerInfo, 'borrowerInfo[0].consentToContact', true);
            _.set(this.borrowerInfo, 'borrowerInfo[0].consentedToContactOn', new Date());

            let body = {
                otherSiteLoan: obj,
                borrowerData: this.borrowerData,
                borrowerInfo: this.borrowerInfo,
                loanAndProperty: this.loanAndProperty,
                demographicInfo: this.demographicInfo,
                acknowledgement: this.acknowledgement,
                financialInfo: this.financialInfo,
                declaration: this.declaration,
                transactionDetail: this.transactionDetail
            };
            if (!body.borrowerData.loanTxnId) {
                body.borrowerData.loanTxnId = this.$random(8);
            }
            body.loanAndProperty.loanTxnId = body.borrowerInfo.loanTxnId = body.demographicInfo.loanTxnId = body.acknowledgement.loanTxnId = body.financialInfo.loanTxnId = body.declaration.loanTxnId = body.transactionDetail.loanTxnId =
                body.borrowerData.loanTxnId;
            let response = await Axios.post(
                BASE_API_URL + "loan/submitBorrowerInfo",
                body
            );
            if (response.data.type == "error") {
                if (response.data.emailExist) {
                    this.emailExist = response.data.info;
                }
            } else {
                this.$snotify.success("Data has been submitted successfully");
                this.verified = "verification";
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.inProcess = false;
        }
    }

    /**
     * Send the email to the borrower if the email is not send on the borrower email for the first time
     */
    public async resendVerificationLink() {
        try {
            let response = await Axios.post(
                BASE_API_URL + "authentication/resendMailToBorrower", {
                    email: this.borrowerInfo.borrowerInfo[0].personalInfo
                        .contactInformation.email
                }
            );
            if (response.data.message == "success") {
                this.$snotify.success("Verification mail has been successfully sent.");
            }
        } catch (error) {
            console.log(error.response);
        }
    }

    /******************************************************************************************
     *  Verify otherSite token (MLO Or BROKER) to apply the otherSite loan
     ****************************************************************************************** */
    public async verifyOtherSiteToken() {
        try {
            let data = {
                token: this.$route.query.brokerToken ?
                    this.$route.query.brokerToken : this.$route.query.mloToken,
                userType: this.$route.query.brokerToken ? "Broker" : "MLO"
            };
            let response = await Axios.post(
                BASE_API_URL + "authentication/verifyOtherSiteToken",
                data
            );
            if (response.data.errorMessage) {
                this.$snotify.error(response.data.errorMessage);
                this.$router.push({
                    name: "SignUp"
                });
            } else if (this.$store.state.sessionObject.userInfo) {
                if (
                    isStepsNotComplete(this.$store.state.sessionObject.userInfo.completedPreRequisites, 'agreement')
                ) {
                    this.$router.push({
                        path: this.$getCurrentUserTypePath("mlo-pre-requisites")
                    });
                } else {
                    this.$router.push({
                        path: this.$getCurrentUserTypePath("dashboard")
                    });
                }
            } else {
                this.displayfields = true;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async mounted() {
        // OtherSite loan
        if (
            this.$route.query.hasOwnProperty("brokerToken") ||
            this.$route.query.hasOwnProperty("mloToken")
        ) {
            this.verifyOtherSiteToken();
            // Verify the email account of any user type
        } else if (this.$route.query.id) {
            this.token = this.$route.query.id;
            this.verifyActivationToken();
        } else if (this.$route.query.inviteeToken) {
            this.token = this.$route.query.inviteeToken;
            await this.verifyActivationToken();
        }
    }

    /**
     * Delete any opened session before enter in the activation page
     */
    async beforeRouteEnter(to, from, next) {
        await store.dispatch("clearSession");
        next();
    }

    public get brandingInformation() {
        return this.$store.state.brandingInformation || null;
    }

    public get isBorrower() {
        return _.get(this.user, 'type') === "Borrower";
    }
}
